import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { PrinterService } from '@proman/services/printer.service';
import { FileEntityInterface } from '@proman/resources/file';
import { CONFIG } from '@proman/config';
import { AuthService } from '@proman/services/auth.service';

@Component({
    selector: 'pm-text-preview-dialog',
    template: `
        <pro-dialog-title [title]="data.header | translate">
            <div class="dialog-title-actions">
                <pro-btn *ngIf="data?.type === 'html'"
                        [tabIndex]="-1"
                        (onClick)="copyTable()"
                        icon="clipboard"
                        theme="white"
                        [tooltip]="'print' | translate"></pro-btn>
            </div>
        </pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <div *ngIf="data?.type === 'html'">
                <div id="htmlContent" [innerHTML]="data.text | safeHTML"></div>
            </div>
            <div *ngIf="!(data.type || data.type === 'html')">
                <div class="WhiteSpacePreWrap">{{ data.text }}</div>
            </div>
        </div>
        <pro-dialog-actions>
            <pro-btn *ngIf="data?.text && !data?.disablePrint"
                    theme="accent"
                    icon="print"
                    [tooltip]="'print' | translate"
                    (onClick)="printText()"></pro-btn>
            <pro-btn *ngIf="data?.text && data?.downloadable"
                     theme="accent"
                     icon="file-pdf"
                     [tooltip]="'save_as_pdf' | translate"
                     (onClick)="download()"></pro-btn>
        </pro-dialog-actions>
    `
})

export class TextPreviewDialogComponent implements OnInit, OnDestroy {
    customPrintCallback: (event: KeyboardEvent) => void;
    file = true;
    downloadUrl: string;
    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private Auth: AuthService,
        private Printers: PrinterService,
        private dialogRef: MatLegacyDialogRef<TextPreviewDialogComponent>,
    ) {
        this.customPrintCallback = (e) => {
            if ((e.ctrlKey || e.metaKey) && (e.key === 'p' || e.charCode === 16 || e.charCode === 112 || e.keyCode === 80)) {

                e.cancelBubble = true;
                e.preventDefault();

                e.stopImmediatePropagation();

                this.Printers.print(this.data.text);
            }
        };
        this.file = (this.data.file === false) ? this.data.file : this.file;
    }

    printText = () => {
        this.Printers.print(this.data.text);
    }

    ngOnInit(): void {
        window.addEventListener('keydown', this.customPrintCallback);
    }

    ngOnDestroy(): void {
        window.removeEventListener('keydown', this.customPrintCallback);
    }

    download() {
        (this.Entity.get('file') as FileEntityInterface)
            .create({ html: this.data.text })
            .then((file: any) => {
                // console.log(file);
                const downloadUrl = `${CONFIG.api}file/download?id=${file.newId}`;
                this.downloadUrl = downloadUrl;
                window.open(downloadUrl, '_blank');
            });
    }

    copyTable() {
        const urlField = document.getElementById('htmlContent');
        const range = document.createRange();
        range.selectNode(urlField);
        window.getSelection().addRange(range);
        document.execCommand('copy');
    }



}
